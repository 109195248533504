import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["brightness", "effect", "entity_name"]
  connect() {
    console.log("Hello, Stimulus!", this.element)
  }
  toggle() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
  }
  updateBrightness() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const brightness = this.brightness()
    this.brightnessTarget.value = brightness
    return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_brightness?brightness=${brightness}`).then((response) => {

    })
  }
  brightness() {
    return parseInt(this.brightnessTarget.value)
  }
  updateEffect() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const effect = this.effect()
    this.effectTarget.value = effect
    return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_effect?effect=${effect}`).then((response) => {
    })
  }
  effect() {
    return this.effectTarget.value
  }
  updateName() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const entity_name = this.entityName()
    this.nameTarget.value = entity_name
    return fetch(`/admin/api/iot/entity/${iot_entity_id}/update_entity_name?name=${entity_name}`).then((response) => {
    })
  }
  entityName() {
    return this.nameTarget.value
  }
}
